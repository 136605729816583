<script>
import {getAllFollowings}from "@/services/api/user"
import { userMethods } from "@/state/helpers";

/**
 * Advanced table component
 */
export default {
  data() {
    return {
      tableData: [],
      title: "",
      items: [
        {
          text: "Users",
          href: "/",
        },
        {
          text: "Normal",
          active: true,
        },
      ],
      rows: 0,
      currentPage: 1,
      currentUser:null,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],

      fields: [
        { key: "user", sortable: false, label: "User" },
        { key: "details", sortable: false, label: "Details" },
      ],
      dataLoading: false,
    };
  },
  computed: {

  },
  mounted() {

  },
  created() {
    this.getFollowingUsers();
  },
  methods: {
    /**
     * Search the table data with search input
     */
    ...userMethods,
    getFollowingUsers() {
      this.dataLoading = true;
      this.tableData = []
      getAllFollowings({ userId:this.$store.state.users.seleted._id,page: this.currentPage, perPage: this.perPage, role: "Normal" })
        .then((res) => {
          this.tableData = res.data.following;
          this.rows= res.data.count;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.dataLoading = false;
          console.log | "Loaded";
        });
    },
    pageChange(val){
      this.currentPage = val;
      this.getFollowingUsers();

    },
    perPageChange(){
     this.getFollowingUsers();

    }
  },
};
</script>

<template>
<div>
  

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- Table -->
            <div class="no-data" v-if="!dataLoading && !rows">
               This is user is not following any one!
            </div>
            <div class="table-responsive mb-0" style="min-height: 70vh">
              <b-table
                :items="tableData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :busy="dataLoading"
                :hover="true"
               thead-class="d-none"
              >
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="pl-4">Loading...</strong>
                  </div>
                </template>
                <template #cell(following)="data">
                  {{ data.item.following.length }}
                </template>
                <template #cell(user)="data">
                  <b-avatar
                    rounded
                    :src="data.item.profile_image"
                    size="4rem"
                  ></b-avatar
                  >
                  <div style="font-weight:800">
                       {{ data.item.fullname }}
                  </div>
               
                </template>
                <template #cell(details)="data">
                  <strong>Email : </strong> {{ data.item.email }} <br />
                  <strong>Mobile : </strong> {{ data.item.phone_no }}
                </template>
            
              
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="rows">
      <div class="col-sm-5">
        <div class="float-left">
               <label class="d-inline-flex align-items-center" style="min-width:45px">
            Show&nbsp;
            <b-form-select
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              @change="perPageChange"
            ></b-form-select
            >&nbsp;entries
          </label>
        </div>    
      </div>
      <div class="col-sm-7">
        <div class="float-right">
          <ul class="pagination pagination-rounded mb-4">
            <!-- pagination -->
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              @change="pageChange"
            ></b-pagination>
          </ul>
          
        </div>
      </div>
    </div>
</div>

</template>
<style scoped>
.row:hover{
  cursor: pointer;
}
.no-data{
  text-align: center;
  padding-top: 14px;
  font-size:16px;
  font-weight: 600;
}
</style>